import Firebase from 'firebase/app'
import { recruitsCollection } from './firebase'
import { cleanData } from '../shared/utils'

/**
 * Check if a recruit already exists by email.
 * @param {string} email - The email of the recruit.
 * @returns {boolean} - Returns true if the recruit exists, false otherwise.
 */
export async function recruitExists(email) {
  try {
    const doc = await recruitsCollection.doc(email).get()
    return doc.exists
  } catch (error) {
    console.error('Error checking recruit existence:', error)
    throw new Error('Failed to check recruit existence')
  }
}

/**
 * Save a recruit if they do not already exist.
 * @param {Object} recruitData - The recruit data to save.
 * @returns {Object} - Returns a success message with the recruit ID if saved.
 */
export async function saveRecruit(recruitData) {
  try {
    const { email: id, ...data } = recruitData

    // Check if the recruit already exists
    const exists = await recruitExists(id)
    if (exists) {
      console.log(`Recruit with email ${id} already exists.`)
      return { success: false, message: 'Recruit already exists', id }
    }

    // Clean undefined fields, including nested objects
    const cleanRecruitData = cleanData(data)

    const recruit = {
      ...cleanRecruitData,
      createdAt: Firebase.firestore.FieldValue.serverTimestamp(),
    }

    await recruitsCollection.doc(id).set(recruit, { merge: true })
    return { success: true, id }
  } catch (error) {
    console.error('Error saving recruit:', error)
    throw new Error('Failed to save recruit')
  }
}

export async function updateRecruit(email, formData) {
  try {
    // Clean undefined fields, including nested objects
    const cleanRecruitData = cleanData(formData)

    const recruit = {
      ...cleanRecruitData,
      updatedAt: Firebase.firestore.FieldValue.serverTimestamp(),
    }

    await recruitsCollection.doc(email).update(recruit)
    return { success: true, id: email }
  } catch (error) {
    console.error('Error updating recruit:', error)
    throw new Error('Failed to update recruit')
  }
}
