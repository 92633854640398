<template>
  <div ref="videoContainer" class="w-full h-auto"></div>
</template>

<script>
import { supportedLangs } from '@/i18n'

export default {
  name: 'LocalizedVideo',
  props: {
    enVideo: {
      type: Object,
      required: true,
      validator: (obj) => 'src' in obj && 'uuid' in obj,
    },
    esVideo: {
      type: Object,
      default: null,
      validator: (obj) => obj === null || ('src' in obj && 'uuid' in obj),
    },
  },
  mounted() {
    this.renderVideo()
    this.removeBacklink()
  },
  watch: {
    '$i18n.locale': {
      handler() {
        this.renderVideo()
      },
    },
  },
  methods: {
    renderVideo() {
      const videoContainer = this.$refs.videoContainer
      if (!videoContainer) return

      videoContainer.innerHTML = ''

      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = 'https://play.vidyard.com/embed/v4.js'
      script.async = true
      document.head.appendChild(script)

      const locale = this.$i18n.locale
      const { src, uuid } = (locale === supportedLangs.es && this.esVideo)
        ? this.esVideo
        : this.enVideo

      const image = document.createElement('img')
      image.style.width = '100%'
      image.style.margin = 'auto'
      image.style.display = 'block'
      image.className = 'vidyard-player-embed'
      image.src = src
      image.setAttribute('data-uuid', uuid)
      image.setAttribute('data-v', '4')
      image.setAttribute('data-type', 'inline')

      videoContainer.appendChild(image)
    },
    removeBacklink() {
      setTimeout(() => {
        const backlink = document.getElementById('backlink')
        if (backlink) {
          backlink.remove()
        }
      }, 1500)
    },
  },
}
</script>
