<template>
  <section :id="id" class="promotional-video-section">
      <PageWrapper>
        <LocalizedVideo :enVideo="enVideo" :esVideo="esVideo" />
      </PageWrapper>
  </section>
</template>

<script>
import PageWrapper from '@/components/PageWrapper/PageWrapper'
import LocalizedVideo from '@/components/LocalizedVideo/LocalizedVideo.vue'

export default {
  props: {
    id: String,
  },
  components: {
    PageWrapper,
    LocalizedVideo,
  },
  data() {
    return {
      enVideo: {
        src: 'https://play.vidyard.com/4J8pEdZtkaLN3bBxqFBbNZ.jpg',
        uuid: '4J8pEdZtkaLN3bBxqFBbNZ',
      },
      esVideo: {
        src: 'https://play.vidyard.com/cXq7eQZPqgtqXwcXHt3gzh.jpg',
        uuid: 'cXq7eQZPqgtqXwcXHt3gzh',
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/base.scss";

.promotional-video-section {
  background-color: $secondaryColor;
  padding: 4rem 0 2rem;

  div:first-child {
    max-width: $tabletWidth;
  }
}

</style>
