import Vue from 'vue'
import { recruitExists, saveRecruit, updateRecruit } from '@/db/recruits'
import {
  VIDEOGAMES,
  CONSOLES_LIST,
  SUBSCRIPTION,
  PREFERRED_TIMES,
} from '@/shared/constants'

const state = {
  currentStep: 1,
  stepReached: 1,
  isStepValid: false,
  showFinishMessage: false,
  formSaved: null,
  emailInUse: null,
  initialEmail: null,
  steps: [
    { id: 1, titleI18n: 'personalInfoStep.stepTitle', icon: 'user' },
    { id: 2, titleI18n: 'gamerInfoStep.stepTitle', icon: 'gamepad' },
    { id: 3, titleI18n: 'discordInviteStep.stepTitle', icon: 'discord' },
    { id: 4, titleI18n: 'downloadAppStep.stepTitle', icon: 'download' },
    { id: 5, titleI18n: 'joinNextTournamentStep.stepTitle', icon: 'trophy' },
    { id: 6, titleI18n: 'thankYouStep.stepTitle', icon: 'done' },
  ],
  formData: {
    // step 1
    name: null,
    email: null,
    country: null,
    whatsapp: null,
    acceptedTerms: false,
    stepReached: 1,
    // step 2
    console: {
      id: null,
      customName: '',
    },
    videoGame: null,
    gameMode: {
      id: null,
      clubName: null,
      clubContact: null,
    },
    onlineSubscription: {
      hasSubscription: false,
      subscriptionType: null,
    },
    onlineId: '',
    competitivePlay: null,
    // step 3
    discordUsername: '',
    isStreamer: false,
    broadcastLink: '',
    alreadyJoinedDiscord: false,
    alreadyDownloadedApp: false,
    alreadyRegisteredInTournament: false,
    adminCommunityDetails: null,
    invitedBy: null,
  },
}

const mutations = {
  setStep(state, step) {
    state.currentStep = step
    if (step > state.stepReached) {
      state.stepReached = step
      Vue.set(state.formData, 'stepReached', step)
    }
    state.isStepValid = false
    state.showFinishMessage = false
  },
  updateFormData(state, { field, value }) {
    Vue.set(state.formData, field, value)
  },
  setValidationStatus(state, isValid) {
    state.isStepValid = isValid
  },
  setFinishMessage(state, show) {
    state.showFinishMessage = show
  },
  setFormSaved(state, saved) {
    state.formSaved = saved
  },
  setEmailInUse(state, inUse) {
    state.emailInUse = inUse
  },
  setInitialEmail(state, email) {
    state.initialEmail = email
  },
}

const actions = {
  setStep({ commit }, step) {
    if (step < state.currentStep) {
      commit('setStep', step)
    }
  },
  updateFormData({ commit }, { field, value }) {
    commit('updateFormData', { field, value })
  },
  async nextStep({ commit, state, dispatch }) {
    if (state.currentStep < state.steps.length) {
      await dispatch('saveForm')
      commit('setStep', state.currentStep + 1)
    }
  },
  prevStep({ commit, state }) {
    if (state.currentStep > 1) {
      commit('setStep', state.currentStep - 1)
    }
  },
  setValidationStatus({ commit }, isValid) {
    commit('setValidationStatus', isValid)
  },
  async setFinishMessage({ commit, state, dispatch }, show) {
    commit('setFinishMessage', show)
    if (show) {
      commit('updateFormData', {
        field: 'stepReached',
        value: state.currentStep,
      })
      await dispatch('saveForm')
    }
  },
  resetEmailInUse({ commit }) {
    commit('setEmailInUse', null)
  },
  async validateEmail({ commit, state }) {
    const email = state.formData.email
    if (!email) {
      return // Skip validation if email is empty
    }

    const exists = await recruitExists(email)
    commit('setEmailInUse', exists)
  },
  async saveForm({ commit, state }) {
    try {
      if (!state.initialEmail) {
        await saveRecruit(state.formData)
        commit('setInitialEmail', state.formData.email)
        commit('setFormSaved', true)
      } else {
        await updateRecruit(state.initialEmail, state.formData)
        commit('setFormSaved', true)
      }
    } catch (error) {
      commit('setFormSaved', false)
    }
  },
}

const getters = {
  isStepValid: (state) => state.isStepValid,
  formData: (state) => state.formData,
  showFinishMessage: (state) => state.showFinishMessage,
  formSaved: (state) => state.formSaved,
  emailInUse: (state) => state.emailInUse,
  VIDEOGAMES: () => VIDEOGAMES,
  CONSOLES: () => CONSOLES_LIST,
  PREFERRED_TIMES: () => PREFERRED_TIMES,
  SUBSCRIPTION: () => SUBSCRIPTION,
  stepReached: state => state.stepReached,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
