export const FEATURES = {
  uleaguePlayer: [
    'uleaguePlayer.features.searchParticipate',
    'uleaguePlayer.features.knowDetail',
    'uleaguePlayer.features.matchesList',
    'uleaguePlayer.features.chatOpponents',
    'uleaguePlayer.features.registerResults',
    'uleaguePlayer.features.statistics',
    'uleaguePlayer.features.shareAndRate',
    {
      icon: 'secure.svg',
      text: 'uleaguePlayer.features.secureMoney',
    },
  ],
  // Default number
  uleaguePlayerNum: 350,
  uleagueAdmin: [
    'uleagueAdmin.features.createAndEdit',
    'uleagueAdmin.features.chatWithPlayers',
    'uleagueAdmin.features.warrantedMoney',
    {
      text: 'uleagueAdmin.features.customize',
      subFeatures: [
        'uleagueAdmin.features.selectTournament',
        'uleagueAdmin.features.rules',
        'uleagueAdmin.features.prizes',
        'uleagueAdmin.features.chargeFee',
      ],
    },
  ],
  // Default number
  uleagueAdminNum: 24,
}

export const SOCIAL_MEDIA = {
  instagram: 'https://instagram.com/uleagueapp',
  facebook: 'https://www.facebook.com/Uleague-102798395581339',
}

export const CONSOLES = {
  ps4: 'PS4',
  ps5: 'PS5',
  xbox_one: 'XBOX ONE',
  xbox_series_x_s: 'XBOX SERIES X|S',
}

export const GAMES = {
  fifa_19: 'FIFA 19',
  fifa_20: 'FIFA 20',
  fifa_21: 'FIFA 21',
  pes_2019: 'PES 2019',
  pes_2020: 'PES 2020',
  pes_2021: 'PES 2021',
}

export const APPLE_CLIENT_REGEX = /iPod|iPad|iPhone|Macintosh/gi

export const ONELINK_DOWNLOAD_APP_LINK = 'http://onelink.to/pt5zxg'

export const GAME_MODE = {
  ultimateTeam: {
    id: 'ultimateTeam',
    name: 'Ultimate Team',
  },
  clubs: {
    id: 'clubs',
    name: 'Clubs',
  },
  seasons: {
    id: 'seasons',
    name: 'Seasons',
  },
  rush: {
    id: 'rush',
    name: 'Rush',
  },
  dreamTeam: {
    id: 'dreamTeam',
    name: 'Dream Team',
  },
  eFootballLeague: {
    id: 'eFootballLeague',
    name: 'eFootball League',
  },
  events: {
    id: 'events',
    name: 'Events',
  },
}

export const VIDEOGAMES = [
  {
    id: 'fc25',
    name: 'FC 25',
    modes: [GAME_MODE.ultimateTeam, GAME_MODE.clubs, GAME_MODE.seasons, GAME_MODE.rush],
  },
  {
    id: 'fc24',
    name: 'FC 24',
    modes: [GAME_MODE.ultimateTeam, GAME_MODE.clubs, GAME_MODE.seasons],
  },
  {
    id: 'fifa23',
    name: 'FIFA 23',
    modes: [GAME_MODE.ultimateTeam, GAME_MODE.clubs, GAME_MODE.seasons],
  },
  {
    id: 'eFootball',
    name: 'eFootball',
    modes: [GAME_MODE.dreamTeam, GAME_MODE.eFootballLeague, GAME_MODE.events],
  },
]

export const SUBSCRIPTION = {
  psPlus: {
    id: 'psPlus',
    name: 'PlayStation Plus',
    description: 'Required for online multiplayer on PlayStation consoles (PS4 and PS5).',
    playerIdentifierLabel: 'PSN ID',
  },
  gamePass: {
    id: 'gamePass',
    name: 'Game Pass',
    description: 'Necessary for online multiplayer on Xbox consoles (Xbox One and Xbox Series X|S).',
    playerIdentifierLabel: 'Gamertag',
  },
  noSubscription: {
    id: 'noSubscription',
    name: 'No Subscription Required',
    description: 'No additional subscription is needed for online play.',
  },
}

export const CONSOLES_LIST = [
  {
    id: 'ps5',
    name: 'PlayStation 5',
    shortName: 'PS5',
    isNewGen: true,
    logo: 'logos/consoles/ps.svg',
    color: 'gray',
    subscription: SUBSCRIPTION.psPlus,
  },
  {
    id: 'xbox-series-xs',
    name: 'Xbox Series X|S',
    shortName: 'Series X|S',
    isNewGen: true,
    logo: 'logos/consoles/xbox.svg',
    color: 'gray',
    subscription: SUBSCRIPTION.gamePass,
  },
  {
    id: 'ps4',
    name: 'PlayStation 4',
    shortName: 'PS4',
    isNewGen: false,
    logo: 'logos/consoles/ps.svg',
    color: 'gray',
    subscription: SUBSCRIPTION.psPlus,
  },
  {
    id: 'xbox-one',
    name: 'Xbox One',
    shortName: 'XB1',
    isNewGen: false,
    logo: 'logos/consoles/xbox.svg',
    color: 'gray',
    subscription: SUBSCRIPTION.gamePass,
  },
  {
    id: 'pc',
    name: 'PC',
    shortName: 'PC',
    logo: 'logos/consoles/windows.svg',
    color: 'gray',
    subscription: null,
  },
  {
    id: 'other',
    name: 'Other',
    shortName: 'Other',
    logo: null,
    color: 'gray',
    subscription: null,
  },
]

export const PREFERRED_TIMES = [
  { label: 'Morning', range: '6 AM - 12 PM' },
  { label: 'Afternoon', range: '12 PM - 6 PM' },
  { label: 'Evening', range: '6 PM - 10 PM' },
  { label: 'Night', range: '10 PM - 2 AM' },
  { label: 'Late Night', range: '2 AM - 6 AM' },
]

export const PROFILE_OPTIONS = [
  { id: 'player', title: 'Player', color: 'blue' },
  { id: 'admin', title: 'Administrator', color: 'green' },
  { id: 'spectator', title: 'Spectator', color: 'purple' },
]

export const PROFILE_TYPES = {
  player: 'player',
  admin: 'admin',
  spectator: 'spectator',
}

export const PROFILE_DESCRIPTIONS = {
  [PROFILE_TYPES.player]: [
    'downloadAppStep.profileDescriptions.player.1',
    'downloadAppStep.profileDescriptions.player.2',
    'downloadAppStep.profileDescriptions.player.3',
  ],
  [PROFILE_TYPES.admin]: [
    'downloadAppStep.profileDescriptions.admin.1',
    'downloadAppStep.profileDescriptions.admin.2',
    'downloadAppStep.profileDescriptions.admin.3',
  ],
  [PROFILE_TYPES.spectator]: [
    'downloadAppStep.profileDescriptions.spectator.1',
  ],
}
