<template>
  <section class="footer-section">
    <PageWrapper>
      <div class="footer-grid">
        <div class="social-media">
          <a :href="igSocialMediaUrl" target="_blank">
            <inline-svg
              :src="require('@/assets/icons/ig.svg')"
              title="ULEAGUE on Instagram"
              width="22px"
            ></inline-svg>
          </a>
          <a :href="fbSocialMediaUrl" target="_blank">
            <inline-svg
              :src="require('@/assets/icons/fb.svg')"
              title="ULEAGUE on Facebook"
              width="22px"
            ></inline-svg>
          </a>
        </div>
        <div class="links">
          <div class="link-item">
            <router-link to="/terms">
              {{ $t("terms") }}
            </router-link>
          </div>
          <div class="link-item">
            <router-link to="/privacy-policy">
              {{ $t("privacyPolicy") }}
            </router-link>
          </div>
          <div class="spacer"></div>
          <div class="link-item right-aligned">{{ $t("copyright") }} {{ new Date().getFullYear() }}</div>
          <div class="link-item">
            <LangSelector />
          </div>
        </div>
      </div>
    </PageWrapper>
  </section>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import PageWrapper from '@/components/PageWrapper/PageWrapper'
import LangSelector from '@/components/LangSelector/LangSelector'
import { SOCIAL_MEDIA } from '@/shared/constants'

export default {
  computed: {
    igSocialMediaUrl() {
      return SOCIAL_MEDIA.instagram
    },
    fbSocialMediaUrl() {
      return SOCIAL_MEDIA.facebook
    },
  },
  props: {
    id: String,
  },
  components: {
    InlineSvg,
    LangSelector,
    PageWrapper,
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/base.scss";

.footer-section {
  background-color: $whiteColor;
  padding: 1.6rem 0;

  a {
    text-decoration: none;
    color: $primaryColor;

    &:visited {
      color: $primaryColor;
    }
  }

  .footer-grid {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: $twelve;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    .social-media {
      a {
        margin-right: 2rem;

        &:last-of-type {
          margin-right: 1rem;
        }
      }
    }

    .links {
      flex-grow: 1;
      display: flex;
      align-items: center;

      .link-item {
        margin: 0 0.3rem;

        &.right-aligned {
          text-align: right;
        }

        a {
          padding: 0.3rem 0.6rem;
        }
      }
    }
  }

  @media (max-width: $tabletWidth) {
    .footer-grid {
      flex-wrap: wrap-reverse;

      .social-media {
        width: 100%;

        a {
          margin-left: 10px;
        }

        svg {
          width: 32px;
        }
      }

      .links {
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 1px solid $grey4Color;
        margin-bottom: 1.2rem;

        .link-item {
          margin: 0 0 2rem;
          text-align: left;

          a {
            padding: 1rem 1rem 1rem 0;
          }
        }
      }
    }
  }
}
</style>
