<template>
  <Card class="torunament-card">
    <div
      class="tournamet-card-head"
      :style="{ backgroundImage: `url(${tournament.imageUrl})` }"
    ></div>
    <div class="tournament-card-info">
      <h3>{{ tournament.name }}</h3>
      <div class="game-console">
        <span>{{ game }}</span> {{ console }}
      </div>
      <div class="fee-prizes">
        <div class="fee">
          <inline-svg
            :src="require('@/assets/icons/price.svg')"
            height="18"
          ></inline-svg>
          ${{ tournament.registrationFee }}
        </div>
        <div class="prizes">
          <inline-svg
            :src="require('@/assets/icons/trophy.svg')"
            height="18"
          ></inline-svg>
          ${{ prizeForWinner }}
        </div>
        <div class="players">
          <inline-svg
            :src="require('@/assets/icons/user.svg')"
            height="18"
          ></inline-svg>
          {{ freePlaces }}
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import Card from '@/components/Card/Card'
import { CONSOLES, GAMES } from '@/shared/constants'

export default {
  props: {
    tournament: Object,
  },
  computed: {
    console() {
      return CONSOLES[this.tournament.console]
    },
    freePlaces() {
      return (
        (this.tournament.numPlayers || 0) -
        (this.tournament.numPlayersRegistered || 0)
      )
    },
    game() {
      return GAMES[this.tournament.game]
    },
    prizeForWinner() {
      return (
        (this.tournament.balance && this.tournament.balance.totalPrizeWinner) ||
        0
      )
    },
  },
  components: {
    Card,
    InlineSvg,
  },
}
</script>

<style lang="scss" scoped>
@import "@/css/base.scss";

$iconColor: #314b98;

.torunament-card {
  width: 370px;
  height: 314px;
  background-color: #fff;

  .tournamet-card-head {
    height: 63%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .tournament-card-info {
    height: 37%;
    padding: 1rem 1.7rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    h3 {
      margin: 0;
      font-size: $eighteen;
      color: $primaryColor;
      letter-spacing: 1px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .game-console {
      font-size: $fourteen;
      color: $grey2Color;

      span {
        margin-right: 0.5rem;
      }
    }

    .fee-prizes {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: $fourteen;

      div {
        display: flex;
        align-items: center;
      }

      svg {
        fill: $iconColor;
        margin-right: 0.3rem;
      }
    }
  }

  @media (max-width: $mobileWidth) {
    width: 280px;
    height: 235px;

    .tournament-card-info {
      padding: 10px 20px 18px;

      h3 {
        font-size: $sixteen;
      }

      .game-console {
        font-size: $twelve;

        span {
          margin-right: 0.3rem;
        }
      }

      .fee-prizes {
        font-size: $twelve;

        svg {
          margin-right: 0.17rem;
        }
      }
    }
  }
}
</style>
