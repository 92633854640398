// Utility function to clean undefined values from nested objects
export function cleanData(data) {
  if (Array.isArray(data)) {
    return data.map(cleanData) // Recursively clean arrays
  } else if (data && typeof data === 'object' && data.constructor === Object) {
    return Object.fromEntries(
      Object.entries(data)
        .filter(([_, value]) => value !== undefined) // Remove undefined values
        .map(([key, value]) => [key, cleanData(value)]) // Recursively clean nested objects
    )
  }
  return data // Return primitive values as-is
}
