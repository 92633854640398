<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'ULEAGUE',
    // all titles will be injected into this template
    titleTemplate: '%s | ULEAGUE',
  },
}
</script>

<style lang="scss">
@import "@/css/globals.scss";

#app {
  min-height: 100%;
}

body {
  background-color: $primaryColor;
}
</style>
