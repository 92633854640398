import Firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'

const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG)
Firebase.initializeApp(firebaseConfig)

// utils
const db = Firebase.firestore()
const auth = Firebase.auth()
const analytics = Firebase.analytics()

// collections
const newsletterCollection = db.collection('newsletter')
const tournamentsCollection = db.collection('tournaments')
const usersCollection = db.collection('users')
const appFeaturesCollection = db.collection('app-features')
const recruitsCollection = db.collection('recruits')

export {
  auth,
  analytics,
  db,
  appFeaturesCollection,
  newsletterCollection,
  tournamentsCollection,
  usersCollection,
  recruitsCollection,
}
