import { tournamentsCollection } from './firebase'

const DEFAULT_LIMIT = 10

export function getHomeTournaments(limit = DEFAULT_LIMIT) {
  return tournamentsCollection
    .where('isOfficialTournament', '==', true)
    .orderBy('createdAt', 'desc')
    .limit(limit)
    .get()
}

export async function getNextTournament() {
  try {
    const snap = await tournamentsCollection
      .where('isOfficialTournament', '==', true)
      .orderBy('createdAt', 'desc')
      .limit(1)
      .get()

    if (snap.empty) {
      return null
    }

    return { ...snap.docs[0].data(), id: snap.docs[0].id }
  } catch (error) {
    console.log(error)
    return null
  }
}
